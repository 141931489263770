@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button{
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1 {
  background: -webkit-linear-gradient(45deg,
   rgb(240,166,36) 0%,
    rgb(42,87,128) 33%,
    rgb(178,87,85) 66%
    ,rgb(145,181,119) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  line-height: 2 !important;
}
.fcc
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.backDrop
{
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
}
.arl{
  font-family: 'Tajawal', sans-serif;

}
.enl{
  font-family: 'Acme', sans-serif;

}
.fadeIn{
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
.fadeOut{
  animation: fadeOut 0.5s;
}
@keyframes fadeOut {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

.shake{
  animation: shakeAnim 1s ease-in-out;
}

@keyframes shakeAnim {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.correct{
  animation: correctAnim 1s ease-in-out;
}

@keyframes correctAnim {
 
  to{
    transform: scale(1.75)
  }

  
}

.zoomOut {
  animation: zoomOut 1s 
}
@keyframes zoomOut {
  0%{
    transform: scale(0);
    
  }
  50%{
    opacity: 1;
  }
  100%{
    transform: scale(1.5);
    opacity: 0;
  }
}
.fadeOpacityOut-90{
  animation: fadeOpacityOut 3s;
}
@keyframes fadeOpacityOut {
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}